<template>
  <div id="search-results" :style="{ 'margin-top': offset }">
    <ActiveRefinements />
    <ais-configure :hits-per-page.camel="150" />

    <ais-infinite-hits
      :class-names="{
        'ais-InfiniteHits-loadMore': 'loadResults',
        'ais-InfiniteHits-loadPrevious': 'loadResults',
        'ais-InfiniteHits-list': 'columns is-multiline',
        'ais-InfiniteHits-item': 'column is-12',
      }"
      :transform-items="transform"
    >
      <template slot="item" slot-scope="{ item, index }">
        <Ad ad-slot="ResultsAd" :index="index" />
        <router-link
          :id="item.objectID"
          :to="{ name: 'record', params: { record: item.slug }}"
          class="media"
          :class="`${ item.sponsor ? 'is-featured' : '' }`"
          :aria-label="item.name"
          @mouseleave.native.self="$emit('dehighlight', item.objectID)"
          @mouseover.native="$emit('highlight', item.objectID)"
        >
          <FeaturedBadge :sponsored="item.sponsor" />
          <ExpertBadge :expert="item.expert" />
          <RecordImage
            v-if="item.sponsor"
            :image-uri="item['image-uri']"
            :name="item.name"
          />
          <div class="media-content">
            <h4 class="title is-size-4 is-marginless">
              <span
                v-if="isFilteredView && item.rank !== 9999"
                class="subtitle is-rank"
                v-html="item.rank"
              />
              <ais-highlight :hit="item" attribute="name" />
              <RecordPrimaryFilterLabel
                class="is-size-7 is-primary-filter-label"
                :class="{ 'pl-5': isFilteredView && item.rank !== 9999 }"
                :record="item"
              />
            </h4>
            <RecordDetailSnapshot v-if="item.sponsor" :record="item" />
          </div>
        </router-link>
      </template>
    </ais-infinite-hits>
    <NoResults />
  </div>
</template>

<script>
/* eslint-disable */
import arrays from '@/mixins/arrays';
import {mapState, mapGetters} from 'vuex';
import {createInfiniteHitsSessionStorageCache} from 'instantsearch.js/es/lib/infiniteHitsCache';

export default {
  mixins: [arrays],
  data: () => ({
    cache: createInfiniteHitsSessionStorageCache(),
    offset: '0px',
  }),
  computed: {
    ...mapState({
      results_ads: (state) => state.advertisements.results_ads,
      ad_provider: (state) => state.advertisements.provider,
      hasBanner: (state) => state.hasBanner,
    }),
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    isFilteredView() {
      return Object.keys(this.$route.query).includes(this.currentDirectory.primary_filter.field);
    },
  },
  methods: {
    //TODO: refactor this using a vuex event listener.
    // Set a vuex far to false, when it is true, trigger the calculateOffset method
    // leverage ...mapState()
    calculateOffsetTimer() {
      setTimeout(() => {
        this.calculateOffset();
      }, 200);
      setTimeout(() => {
        this.calculateOffset();
      }, 1000);
      setTimeout(() => {
        this.calculateOffset();
      }, 2000);
    },
    calculateOffset() {
      //TODO: move to a mixin
      const offset = Math.max(
          document.getElementById('featuredPrimaryBanner')?.offsetHeight,
          0
      ) + 20;
      this.offset = `${offset}px`;
    },
    getRank(record, currentFilter) {
      //TODO: extract to mixin
      // Gets only the FIRST key element for the field.
      // For example, `lists.key` is the primary filter, but we only want to get `lists`
      // as the field.
      const recordField = record[this.currentDirectory.primary_filter.field.split('.')[0]];
      let validRecord;

      // Change `list` to `key` when records in Algolia are updated
      if (Array.isArray(recordField)) {
        validRecord = recordField?.find((field) => field?.key === currentFilter);
      }

      // Return a "rank" of super high so it shows at the bottom of the list temporarily
      // before it's hidden by the facet filter again.
      return validRecord?.rank ?? 9999;
    },
    transform(items) {
      const filter = this.$router.currentRoute.query?.[this.currentDirectory.primary_filter.field];

      // Handle boolean data types that could be strings
      items = items.map((item) => ({ ...item, sponsor: String(item.sponsor) === 'true' }));

      if (filter === undefined) {
        const splitItems = this.groupBy(items, 'sponsor');
        if (splitItems[true]) {
          return [...this.shuffle(splitItems[true]), ...splitItems[false]];
        }
        return items;
      }

      return items
          .map((item) => ({ ...item, rank: this.getRank(item, filter) }))
          .sort((a, b) => a.rank - b.rank);
    },
  },
  watch: {
    hasBanner() {
      this.calculateOffsetTimer();
    },
  },
  created() {
    window.addEventListener('resize', this.calculateOffsetTimer);
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateOffsetTimer);
  },
  mounted() {
    //TODO: This should be redundant once the vuex var is set
    this.calculateOffsetTimer();
  },
  components: {
    ActiveRefinements: () => import('@/components/InstantSearch/ActiveRefinements.vue'),
    Ad: () => import('@/components/Ads/Ad.vue'),
    FeaturedBadge: () => import('@/components/Badges/Featured.vue'),
    ExpertBadge: () => import('@/components/Badges/Expert.vue'),
    RecordImage: () => import('@/components/SearchResults/RecordImage.vue'),
    RecordRank: () => import('@/components/InstantSearch/RecordRank.vue'),
    RecordDetailSnapshot: () => import('@/components/SearchResults/RecordDetailSnapshot.vue'),
    RecordPrimaryFilterLabel: () => import('@/components/SearchResults/RecordPrimaryFilterLabel.vue'),
    NoResults: () => import('@/components/InstantSearch/NoResults.vue'),
  },
};
</script>
