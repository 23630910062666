var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ 'margin-top': _vm.offset }),attrs:{"id":"search-results"}},[_c('ActiveRefinements'),_c('ais-configure',{attrs:{"hitsPerPage":150}}),_c('ais-infinite-hits',{attrs:{"class-names":{
      'ais-InfiniteHits-loadMore': 'loadResults',
      'ais-InfiniteHits-loadPrevious': 'loadResults',
      'ais-InfiniteHits-list': 'columns is-multiline',
      'ais-InfiniteHits-item': 'column is-12',
    },"transform-items":_vm.transform},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('Ad',{attrs:{"ad-slot":"ResultsAd","index":index}}),_c('router-link',{staticClass:"media",class:("" + (item.sponsor ? 'is-featured' : '')),attrs:{"id":item.objectID,"to":{ name: 'record', params: { record: item.slug }},"aria-label":item.name},nativeOn:{"mouseleave":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.$emit('dehighlight', item.objectID)},"mouseover":function($event){return _vm.$emit('highlight', item.objectID)}}},[_c('FeaturedBadge',{attrs:{"sponsored":item.sponsor}}),_c('ExpertBadge',{attrs:{"expert":item.expert}}),(item.sponsor)?_c('RecordImage',{attrs:{"image-uri":item['image-uri'],"name":item.name}}):_vm._e(),_c('div',{staticClass:"media-content"},[_c('h4',{staticClass:"title is-size-4 is-marginless"},[(_vm.isFilteredView && item.rank !== 9999)?_c('span',{staticClass:"subtitle is-rank",domProps:{"innerHTML":_vm._s(item.rank)}}):_vm._e(),_c('ais-highlight',{attrs:{"hit":item,"attribute":"name"}}),_c('RecordPrimaryFilterLabel',{staticClass:"is-size-7 is-primary-filter-label",class:{ 'pl-5': _vm.isFilteredView && item.rank !== 9999 },attrs:{"record":item}})],1),(item.sponsor)?_c('RecordDetailSnapshot',{attrs:{"record":item}}):_vm._e()],1)],1)]}}])}),_c('NoResults')],1)}
var staticRenderFns = []

export { render, staticRenderFns }